import { React, styled } from '@sd-utility/main-dependencies';
import { GridContainer, GridItem, GridRow, gridConfig } from '@tvg/fuse-responsive-grid';
import SpacingWrapper from '@tvg/fuse-spacing-wrapper';
import { VideoPlayer as VideoPlayerFuse } from '@tvg/fuse-video-player';
import buildGoogleAnalyticsTag from '../../utils/buildGoogleAnalyticsTag';
import buildImageSources from '../../utils/content/buildImageSources';
import buildVideoFrame from '../../utils/content/buildVideoFrame';
import buildVideoSources from '../../utils/content/buildVideoSources';
import { RequestParams } from '../../utils/types';
import { Branded, ComponentRenderer, HydratableRendering } from '../types';
import { VideoPlayerComponent } from './types';

export const videoPlayerSchemaId = 'https://theverygroup.com/dcx-templating/modules/video-player';

const StyledGridContainer = styled(GridContainer)`
	&& {
		@media only screen and (min-width: ${gridConfig.l.minWidth}) {
			${({ pageType }) => pageType !== 'standard' && `padding: 0;`}
		}
	}
`;

export const VideoPlayer = ({
	gridWrapper = true,
	title,
	description,
	videoPlayer,
	posterOptions,
	captions,
	brand,
	params,
}: Branded<VideoPlayerComponent>): JSX.Element => {
	const pageType = params?.prefix;

	const populateCaptions =
		captions &&
		captions.map(({ url, label }) => ({
			src: url,
			label,
			type: 'captions',
		}));

	const VideoPlayerJSX: JSX.Element = (
		<VideoPlayerFuse
			titleTag="h2"
			title={title}
			description={description}
			poster={
				posterOptions.posterImage
					? buildImageSources({
							image: posterOptions.posterImage,
							templates: [],
							params: {
								aspect: '16:9',
								w: 740,
								qlt: 'default',
							},
							brand,
					  }).fallbackSource
					: buildVideoFrame(videoPlayer, posterOptions.posterFrame, brand)
			}
			sources={buildVideoSources(videoPlayer, brand)}
			tracks={populateCaptions}
			dataAnalyticsId={buildGoogleAnalyticsTag('VideoPlayer', title || videoPlayer.name)}
		/>
	);

	return gridWrapper ? (
		<SpacingWrapper marginBottom="l">
			<StyledGridContainer pageType={pageType}>
				<GridRow>
					<GridItem
						xxs="12"
						l={pageType === 'standard' ? '10' : '12'}
						colStart-l={pageType === 'standard' ? '2' : '0'}
					>
						{VideoPlayerJSX}
					</GridItem>
				</GridRow>
			</StyledGridContainer>
		</SpacingWrapper>
	) : (
		VideoPlayerJSX
	);
};

export const videoPlayerRenderer: ComponentRenderer<VideoPlayerComponent> = (
	props: VideoPlayerComponent,
	brand: string,
	params?: RequestParams,
): HydratableRendering => ({
	component: <VideoPlayer {...props} brand={brand} params={params} />,
	props: { ...props, brand },
	reference: 'VideoPlayer',
});
